import React from "react";
import { graphql } from "gatsby";

import Layout from "../layouts/home";
import SEO from "../components/SEO";

import contentParser from "gatsby-wpgraphql-inline-images";
import { inlineUrls } from "../utils";
import WorkWithUs from "../components/Forms/workwithus";

class VirtualTour extends React.Component {
  render() {
    const {
      location,
      data: {
        wordPress: {
          pageBy: {
            title,
            acf_subtitle: { subtitle },
            excerpt,
            content,
          },
        },
      },
    } = this.props;

    // console.log(this.props);

    return (
      <Layout location={location} cssClass="about">
        <SEO title={title} description={excerpt} />
        <section id="header" className="p-top p-bottom text-center bg-pink">
          <div className="featured">
            <h1>{title}</h1>
            <div className="lead">
              <p>{subtitle}</p>
            </div>
          </div>
        </section>
        <section id="body" className="bg-charcoal b-top b-bottom">
          <div className="container">
            <div className="row justify-content-center">
              <div className="p-top p-bottom">
                {contentParser({ content }, inlineUrls)}
              </div>
            </div>
          </div>
        </section>
        <WorkWithUs />
      </Layout>
    );
  }
}

export default VirtualTour;

export const contactQuery = graphql`
  {
    wordPress {
      pageBy(uri: "virtual-tour") {
        title
        acf_subtitle {
          subtitle
        }
        excerpt
        content
      }
    }
  }
`;
